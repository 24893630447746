import { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import { Button, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import { DeleteOutline } from "@material-ui/icons";

import Loading from "../../components/Loading";
import SimpleTable from "../../components/SimpleTable";

import API from "../../utils/API";

const tablecells = [
  {
    label: "Email",
    field: "email",
  },
  {
    label: "Nom",
    field: "name",
  },
  {
    label: "Role",
    field: "roles",
  },
  {
    label: "Crée le",
    field: "createdAt",
    type: "date",
  },
  {
    label: "Actions",
    field: "actions",
  },
];
const Settings = () => {
  const [data, setData] = useState([]);

  const [loadingOpen, setLoadingOpen] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const isLogged = useSelector((state) => state.isLogged);
  const [restrictAccess, setRestrictAccess] = useState({
    restrictAdd: true,
    restrictEdit: true,
    restrictDelete: true,
    restrictShow: false,
  });
  useEffect(() => {
    if (isLogged) {
      console.log({
        restrictAdd: isLogged.restrictAdd,
        restrictEdit: isLogged.restrictEdit,
        restrictDelete: isLogged.restrictDelete,
        restrictShow: isLogged.restrictShow,
      });
      setRestrictAccess({
        restrictAdd: isLogged.restrictAdd,
        restrictEdit: isLogged.restrictEdit,
        restrictDelete: isLogged.restrictDelete,
        restrictShow: isLogged.restrictShow,
      });
    }
    return () => {
      setRestrictAccess({
        restrictAdd: true,
        restrictEdit: true,
        restrictDelete: true,
        restrictShow: false,
      });
    };
  }, [isLogged]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingOpen(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/apps/users`
        );
        if (res.code === 200) {
          var rows = res.data;
          rows.map((row, index) => {
            row.roles = row.roles["0"];
            row.actions = (
              <>
                <Link
                  to={
                    restrictAccess.restrictEdit
                      ? "#"
                      : `/users/${row.id}/update`
                  }
                  style={{ color: "inherit" }}
                >
                  <IconButton disabled={restrictAccess.restrictEdit}>
                    <EditIcon />
                  </IconButton>
                </Link>
                <IconButton
                  disabled={restrictAccess.restrictDelete}
                  onClick={async () => {
                    // Do save operation
                    let res = window.confirm(
                      "Vous voulez vraiment supprimer cette enregistrement?"
                    );
                    if (res) {
                      try {
                        const resp = await API(
                          "DELETE",
                          `${process.env.REACT_APP_API_BASE_URL}/users/${row.id}`
                        );
                        alert(resp.msg);
                        setReloadData(!reloadData);
                      } catch (error) {
                        console.error(error);
                      }
                    }
                  }}
                  style={{ color: "inherit" }}
                >
                  <DeleteOutline />
                </IconButton>
              </>
            );
            return row;
          });

          setData(rows);
          setLoadingOpen(false);
        } else {
          alert(
            "Une erreur est survenue, Si cette erreur persiste contactez l'equipe dev"
          );
        }
      } catch (error) {
        alert(
          "Une erreur est survenue, Si cette erreur persiste contactez l'equipe dev"
        );
        console.log(error);
        setLoadingOpen(false);
      }
    };

    fetchData();
    return () => {
      setData([]);
    };
  }, [reloadData, restrictAccess]);

  return (
    <>
      <Loading open={loadingOpen} />
      <h1>Settings</h1>
      <div align="right" style={{ marginBottom: "10px" }}>
        <Link
          to={restrictAccess.restrictAdd ? "#" : "/users/new"}
          style={{ textDecoration: "none", color: "inherit" }}
          disabled={restrictAccess.restrictAdd}
        >
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            disabled={restrictAccess.restrictAdd}
          >
            Ajouter
          </Button>
        </Link>
      </div>

      <SimpleTable data={data} tableCells={tablecells} />
    </>
  );
};

export default Settings;
