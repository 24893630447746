import { useState, useEffect } from "react";
import Loading from "../../../components/Loading";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { useParams, useHistory } from "react-router";
import API from "../../../utils/API";

const EditUser = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("ROLE_USER");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingOpen(true);
        const res = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/users/${id}`
        );

        if (res.code === 200) {
          setEmail(res.data.email);
          setName(res.data.name);
          setRole(res.data.roles[0]);
          setPassword("");
          //   setRole();
        }
        setLoadingOpen(false);
      } catch (error) {
        setLoadingOpen(false);
        console.log(error);
      }
    };
    fetchData();
    return () => {
      setEmail();
      setName();
      setPassword();
      setRole();
    };
  }, [id]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handlePassChange = (e) => {
    setPassword(e.target.value);
  };
  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    //console.log({ email, name, password });

    // if (!password) {
    //   alert("Veuillez entrer le mot de passe");
    //   return false;
    // }
    try {
      setLoadingOpen(true);
      const res = await API(
        "PUT",
        `${process.env.REACT_APP_API_BASE_URL}/users/${id}`,
        {
          name,
          password,
          email,
          role,
        }
      );

      if (res.code === 200) {
        setLoadingOpen(false);
        alert("Utilisateur mis à jour avec succées");
        history.push("/settings");
      } else {
        setLoadingOpen(false);
        alert("Une erreur est survenue, réessayer plus tard");
      }
      console.log(res);
    } catch (error) {
      setLoadingOpen(false);
      console.log(error);
    }
  };
  return (
    <>
      <Loading open={loadingOpen} />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={8} md={6}>
          <h1>Mettre à jour l'utilisateur</h1>
          <Paper style={{ padding: "20px" }}>
            <form onSubmit={handleFormSubmit}>
              <div className="custom__form__control">
                <TextField
                  fullWidth
                  id="form_email"
                  name="email"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <div className="custom__form__control">
                <TextField
                  fullWidth
                  id="form_name"
                  name="name"
                  label="Nom"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
              <div className="custom__form__control">
                <TextField
                  fullWidth
                  id="form_password"
                  name="password"
                  label="Mot de passe"
                  type="password"
                  value={password}
                  onChange={handlePassChange}
                />
              </div>
              <div className="custom__form__control">
                <InputLabel id="select-label">Role</InputLabel>
                <Select
                  fullWidth
                  labelId="select-label"
                  id="select-role"
                  value={role}
                  onChange={handleRoleChange}
                >
                  <MenuItem value={"ROLE_ADMIN"}>Administrateur</MenuItem>
                  <MenuItem value={"ROLE_COMPTA"}>Comptabilité</MenuItem>
                  <MenuItem value={"ROLE_RD"}>Relation donateur</MenuItem>
                  <MenuItem value={"ROLE_LIFE_MEMBER"}>
                    LIFE team member
                  </MenuItem>
                </Select>
              </div>
              <div align="center" className="custom__form__control">
                <Button variant="contained" color="primary" type="submit">
                  Mettre à jour
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
export default EditUser;
