import { useEffect, useState } from "react";
import { Grid, Paper, IconButton, Tooltip } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { useSelector } from "react-redux";

const Dashboard = () => {
  const isLogged = useSelector((state) => state.isLogged);

  const [restrictAccessReporting, setRestrictAccessReporting] = useState(true);

  const [restrictAccessDonor, setRestrictAccessDonor] = useState(true);

  const [restrictAccessDonate, setRestrictAccessDonate] = useState(true);

  const [token, setToken] = useState("");

  useEffect(() => {
    if (isLogged.userInfo) {
      if (isLogged.userInfo.roles.includes("ROLE_ADMIN")) {
        setRestrictAccessReporting(false);
        setRestrictAccessDonor(false);
        setRestrictAccessDonate(false);
      } else if (isLogged.userInfo.roles.includes("ROLE_RD")) {
        setRestrictAccessReporting(false);
        setRestrictAccessDonor(false);
        setRestrictAccessDonate(false);
      } else if (isLogged.userInfo.roles.includes("ROLE_COMPTA")) {
        setRestrictAccessReporting(false);
        setRestrictAccessDonor(true);
        setRestrictAccessDonate(false);
      }
      setToken(isLogged.user.token);
    }
    return () => {
      setRestrictAccessReporting(true);
      setRestrictAccessDonor(true);
      setRestrictAccessDonate(true);
    };
  }, [isLogged]);

  const reportingText = `Gestion des dons / gestion des RF / gestion des donateurs`;
  const donateText = `Gestion des dons - abonnements - donateurs par virement bancaire (Authentification manuel)`;
  const donorAdminText = `Gestion des donateurs - projets - évenements à venir et Collecte du mois (Administration de l'espace donateur)`;
  const donorText = `Espace donateur`;

  return (
    <>
      <Paper style={{ padding: "20px" }}>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item md={3} style={{ width: "100%" }}>
            <Paper
              align="center"
              style={{
                ...customStyle.paperCard,
                background: "#f36e20",
              }}
            >
              <Tooltip title={reportingText}>
                <InfoOutlinedIcon style={customStyle.iconInfo} />
              </Tooltip>
              {restrictAccessReporting ? (
                <div class="restric_access__mask"></div>
              ) : (
                ""
              )}

              <a
                href={`${process.env.REACT_APP_REPORTING_URL}/login?redirecttoken=${token}`}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton aria-label="visit" style={{ color: "white" }}>
                  <LaunchIcon />
                </IconButton>
              </a>
              <h3 align="center">LIFE Reporting</h3>
              <p>reporting.life-ong.net</p>
            </Paper>
          </Grid>

          <Grid item md={3} style={{ width: "100%" }}>
            <Paper
              align="center"
              style={{
                ...customStyle.paperCard,
                background: "#3f51b5",
              }}
            >
              <Tooltip title={donateText}>
                <InfoOutlinedIcon style={customStyle.iconInfo} />
              </Tooltip>
              {restrictAccessDonate ? (
                <div class="restric_access__mask"></div>
              ) : (
                ""
              )}

              <a
                href={`${process.env.REACT_APP_DONATE_URL}/login`}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton aria-label="visit" style={{ color: "white" }}>
                  <LaunchIcon />
                </IconButton>
              </a>
              <h3 align="center">LIFE Donate</h3>
              <p>donate.life-ong.org</p>
            </Paper>
          </Grid>

          <Grid item md={3} style={{ width: "100%" }}>
            <Paper
              align="center"
              style={{
                ...customStyle.paperCard,
                background: "#333",
              }}
            >
              <Tooltip title={donorAdminText}>
                <InfoOutlinedIcon style={customStyle.iconInfo} />
              </Tooltip>
              {restrictAccessDonor ? (
                <div class="restric_access__mask"></div>
              ) : (
                ""
              )}
              <a
                href={`${process.env.REACT_APP_DONOR_ADMINISTRATION_URL}/login?redirecttoken=${token}`}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton aria-label="visit" style={{ color: "white" }}>
                  <LaunchIcon />
                </IconButton>
              </a>
              <h4 align="center">LIFE Donor (administration) </h4>
              <p>donor-administration.life-ong.net</p>
            </Paper>
          </Grid>

          <Grid item md={3} style={{ width: "100%" }}>
            <Paper
              align="center"
              style={{
                ...customStyle.paperCard,
                background: "linear-gradient(to right, #333, #f36e20)",
              }}
            >
              <Tooltip title={donorText}>
                <InfoOutlinedIcon style={customStyle.iconInfo} />
              </Tooltip>
              <a
                href={`${process.env.REACT_APP_DONOR_URL}/login`}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton aria-label="visit" style={{ color: "white" }}>
                  <LaunchIcon />
                </IconButton>
              </a>
              <h3 align="center">LIFE Donor</h3>
              <p>donor.life-ong.net</p>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Dashboard;

const customStyle = {
  paperCard: {
    padding: "30px 20px",
    color: "white",
    position: "relative",
    width: "100%",
    minHeight: "180px",
  },
  iconInfo: {
    position: "absolute",
    right: "10",
    top: "10",
    cursor: "pointer",
  },
};
