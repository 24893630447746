import "./main.css";
import { useState } from "react";
// redux
import { loggIn } from "../../providers/actions";
import { useDispatch, useSelector } from "react-redux";

import { TextField, Paper, Button, FormControl } from "@material-ui/core";

import API from "../../utils/API";
import Loading from "../../components/Loading";
const Login = ({ history }) => {
  const isLoggedIn = useSelector((state) => state.isLogged.isLoggedIn);

  if (isLoggedIn) {
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const pass = event.target.password.value;

    if (!email && !pass) {
      alert("You must enter your email and your password");
      return false;
    }

    setIsLoading(true);
    try {
      const response = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/login_check`,
        {
          username: email,
          password: pass,
        }
      );

      // return false;
      if (response.token) {
        setIsLoading(false);
        dispatch(loggIn(response));
        setTimeout(() => {
          history.push("/");
        }, 2000);
      } else if (response.code === 401) {
        setIsLoading(false);
        //console.log("error", response);
        alert(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      alert("Email or Password are invalid");
    }

    setIsLoading(false);
  };

  return (
    <div className="donate__loginPage__container">
      <Loading open={isLoading} />
      <Paper className="donate__login__paper">
        <h1 align="center">Login</h1>
        <form
          noValidate
          autoComplete="on"
          onSubmit={handleLoginSubmit}
          className="donate__login__form"
        >
          <TextField
            name="email"
            className="donate__login__el"
            type="email"
            label="Email"
          />
          <TextField
            name="password"
            className="donate__login__el"
            type="password"
            label="Password"
          />
          <FormControl>
            <Button variant="contained" color="primary" type="submit">
              Login
            </Button>
          </FormControl>
        </form>
      </Paper>
    </div>
  );
};

export default Login;
