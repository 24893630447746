const now = new Date().getTime();
const expirationTime = localStorage.getItem("expiration");
if (expirationTime < now) {
  // localStorage.clear();
  localStorage.removeItem("expiration");
  localStorage.removeItem("user");
}
const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? {
      isLoggedIn: true,
      user,
      restrictAdd: true,
      restrictEdit: true,
      restrictDelete: true,
      restrictShow: true,
    }
  : {
      isLoggedIn: false,
      user: null,
      restrictAdd: true,
      restrictEdit: true,
      restrictDelete: true,
      restrictShow: true,
    };

// const initialState = {
//   isLoggedIn: true,
//   user: {
//     id: 11,
//     email: "developpeur@life-ong.org",
//     first_name: "Maroine",
//   },
// };

const LoggedReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "IS_SIGNED_IN":
      const roles = payload.roles;
      const restrictAdd = !roles.includes("ROLE_ADMIN");
      const restrictEdit = !roles.includes("ROLE_ADMIN");
      const restrictDelete = !roles.includes("ROLE_ADMIN");
      const restrictShow = !(
        roles.includes("ROLE_ADMIN") ||
        roles.includes("ROLE_COMPTA") ||
        roles.includes("ROLE_RD") ||
        roles.includes("ROLE_LIFE_MEMBER")
      );
      return {
        ...state,
        userInfo: payload,
        restrictAdd,
        restrictEdit,
        restrictDelete,
        restrictShow,
      };

    case "SIGN_IN":
      localStorage.setItem("user", JSON.stringify(payload));
      const hours = 40;
      const expirationTime = hours * 60 * 60 * 1000;
      localStorage.setItem("expiration", new Date().getTime() + expirationTime);
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
      };

    case "SIGN_OUT":
      localStorage.removeItem("expiration");
      localStorage.removeItem("user");
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    default:
      return state;
  }
};

export default LoggedReducer;
