import { useState, useEffect } from "react";
import Loading from "../../components/Loading";
import { TextField, Button, Grid, Paper } from "@material-ui/core";
import API from "../../utils/API";
import "./main.css";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const [passwordConf, setPasswordConf] = useState("");
  const handlePasswordConfChange = (e) => {
    setPasswordConf(e.target.value);
  };
  const [username, setUsername] = useState("");
  const handleUserNameChange = (e) => {
    setUsername(e.target.value);
  };

  useEffect(() => {
    const loggedinUser = async () => {
      try {
        setLoading(true);
        const response = await API(
          "GET",
          `${process.env.REACT_APP_API_BASE_URL}/users/logged-in`
        );
        if (response.code && response.code === 200) {
          setUserId(response.user.id);
          setEmail(response.user.email);
          setUsername(response.user.name);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    loggedinUser();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password || !passwordConf || !username) {
      alert("Veillez vérifier vos informations");
      return false;
    }

    if (password !== passwordConf) {
      alert("Les mots de passe ne sont pas identiques");
      return false;
    }

    try {
      setLoading(true);
      const response = await API(
        "PUT",
        `${process.env.REACT_APP_API_BASE_URL}/users/${userId}`,
        {
          password: password,
          name: username,
        }
      );

      if (response.code === 200) {
        setLoading(false);
        alert("Mot de passe mis à jour avec succées");
        setPassword("");
        setPasswordConf("");
      } else {
        setLoading(false);
        alert(
          "Une erreur est survenue, si cette erreur persiste contactez l'équipe dev"
        );
      }
    } catch (error) {
      console.log(error);

      setLoading(false);
    }
  };

  return (
    <>
      <Loading open={loading} />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={8} md={6}>
          <h1>Profile</h1>
          <Paper style={{ padding: "20px" }}>
            <form onSubmit={handleFormSubmit}>
              <div className="custom__form__control">
                <TextField
                  fullWidth
                  id="form_email"
                  name="email"
                  label="Email"
                  value={email}
                  disabled
                />
              </div>
              <div className="custom__form__control">
                <TextField
                  fullWidth
                  id="form_name"
                  name="name"
                  label="Nom"
                  value={username}
                  onChange={handleUserNameChange}
                />
              </div>
              <div className="custom__form__control">
                <TextField
                  fullWidth
                  id="form_password"
                  name="password"
                  label="Mot de passe"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
              <div className="custom__form__control">
                <TextField
                  fullWidth
                  id="form_password-conf"
                  name="passwordConf"
                  label="Confirmez le mot de passe"
                  type="password"
                  value={passwordConf}
                  onChange={handlePasswordConfChange}
                />
              </div>
              <div align="center" className="custom__form__control">
                <Button variant="contained" color="primary" type="submit">
                  Mettre à jour
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
