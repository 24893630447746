import React, { useState, useRef } from "react";

import { Button, Grid, Paper, TextField, Switch } from "@material-ui/core";

const EmailSignature = () => {
  const output = useRef(null);
  const [htmlCode, setHtmlCode] = useState("");
  // const handleNameChange = (e) => {
  //   setHtmlCode(e.target.value);
  // };

  const [name, setName] = useState("");
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const [nameSizeD, setNameSizeD] = useState(17);
  const handleNameSizeDChange = (e) => {
    setNameSizeD(e.target.value);
  };

  const [position, setPosition] = useState("");
  const handlePositionChange = (e) => {
    setPosition(e.target.value);
  };

  const [positionSizeD, setPositionSizeD] = useState(16);
  const handlePositionSizeDChange = (e) => {
    setPositionSizeD(e.target.value);
  };

  const [phone, setPhone] = useState("");
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const [phoneSize, setPhoneSize] = useState(12);
  const handlePhoneSizeChange = (e) => {
    setPhoneSize(e.target.value);
  };

  const [email, setEmail] = useState("");
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const [emailSize, setEmailSize] = useState(12);
  const handleEmailSizeChange = (e) => {
    setEmailSize(e.target.value);
  };

  const [address, setAddress] = useState("1 rue de Stockholm 75008 Paris");
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const [addressSize, setaddressSize] = useState(12);
  const handleAddressSizeChange = (e) => {
    setaddressSize(e.target.value);
  };
  const [addressState, setaddressState] = useState(true);
  const handleAddressStateChange = (e) => {
    setaddressState(!addressState);
  };

  let html = `
    <!--[if gte mso 9]>
<![endif]-->
    <!--[if gte mso 9]>
        <table cellspacing=0 style="width: 550px; padding:10px;background: white;font-family: 'Raleway', 'roboto', 'Arial', sans-serif; background-image: url('https://donor.life-ong.net/mail-signature-assets/bg-signature-email.png');background-repeat: no-repeat; background-position: right 20px center;">
            <tr>
                    <td>
                <![endif]-->
    <div
        style="font-family: 'Raleway', 'roboto', 'Arial', sans-serif; padding: 25px 0;background-image: url('https://donor.life-ong.net/mail-signature-assets/bg-signature-email.png');background-repeat: no-repeat; background-position: right 20px center; width: 550px; max-width: 100%;">
        <div style="min-height:100px ;width: 100%;">
            <div style="width: 285px;display: inline-block; ">
                <img src="https://donor.life-ong.net/mail-signature-assets/logo-signature.png" width="80" alt="">
                <div
                    style="background-size: contain;padding-top: 10px; margin-top: 10px;background-image: url('https://donor.life-ong.net/mail-signature-assets/carre-gauche-signature.png');background-repeat: no-repeat">
                    <p style="font-weight: bold;margin: 5px 0 5px;font-family: 'Raleway', 'roboto', 'Arial', sans-serif; font-size: ${nameSizeD}px">${
    name ? name : "Votre nom"
  }</p>
                    <p style="font-family: 'Raleway', 'roboto', 'Arial', sans-serif;font-weight: bold;margin: 5px 0 5px;text-transform:uppercase ;font-size: ${positionSizeD}px; color: #f36e20">${
    position ? position : "Position"
  }</p>
                </div>
            </div>
            <!--[if gte mso 9]>
                </td>
                <td>
            <![endif]-->
            <div style="width: 260px;display: inline-block;vertical-align: bottom;margin-bottom: 6px;">
                <table>
                    <tr>
                        <td style="line-height: 17px; width:18px; text-align: center"><img style="max-height: 13px;max-width:13px"
                                src="https://donor.life-ong.net/mail-signature-assets/icon-phone-signature.png" alt="phone"></td>
                        <td style="font-family: 'Raleway', 'roboto', 'Arial', sans-serif;font-weight: bold; font-size: ${phoneSize}px">${
    phone ? phone : "Numero de téléphone"
  }</td>
                    </tr>
                    <tr>
                        <td style="line-height: 17px; width:18px; text-align: center"><img style="max-height: 9px;max-width:12px"
                                src="https://donor.life-ong.net/mail-signature-assets/icon-mail-signature.png"
                                alt="email"></td>
                        <td style="font-family: 'Raleway', 'roboto', 'Arial', sans-serif;font-weight: bold; font-size:  ${emailSize}px"> ${
    email ? email : "email@life-ong.org"
  }</td>
                    </tr>
                    <tr style="${addressState ? "" : "display: none"}">
                        <td style="font-family: 'Raleway', 'roboto', 'Arial', sans-serif;line-height: 17px; width:18px; text-align: center"><img style="max-height: 13px;max-width:13px"
                                src="https://donor.life-ong.net/mail-signature-assets/icon-local-signature.png"
                                        alt="adresse">
                        </td>
                        <td style="font-family: 'Raleway', 'roboto', 'Arial', sans-serif;font-weight: bold; font-size: ${addressSize}px;">${
    address ? address : "1 rue de Stockholm 75008 Paris"
  }</td>
                    </tr>
                </table>

            </div>
        </div>
        <!--[if gte mso 9]>
                </td>
            <![endif]-->
        <!--[if gte mso 9]>   
            </tr> 
            <tr>
        <![endif]-->
        <div style="text-align: justify; width: 100%;">
            <p style="font-family: 'Raleway', 'roboto', 'Arial', sans-serif;max-width:100%;font-size: 7px; margin:0 20px 5px 0;">
                Le contenu de cet email est confidentiel et uniquement destiné au destinataire spécifié dans le message,
                il
                est strictement interdit de partager une partie de ce message avec un tiers, sans le consentement écrit
                de
                l'expéditeur. Si vous avez reçu ce message par erreur, merci de nous en informer afin de nous assurer
                qu'une
                telle erreur ne se produise plus à l'avenir.
            </p>
        </div>
    </div>
    <!--[if gte mso 9]> 
                    </tr>
                <![endif]-->
    </div>
    <!--[if gte mso 9]> 
        </table>
        <![endif]-->`;

  const handleHtmlGeneration = () => {
    setHtmlCode(html);
    output.current.textContent = html;
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(htmlCode);
    alert("Code html copié !");
  };
  return (
    <>
      <div>
        <h2>Signature Email</h2>

        <Grid container spacing={4}>
          <Grid item md={4} sm={4} xs={12}>
            <Paper style={styles.paper}>
              <p>
                Remplissez le formulaire, adaptez la taille du texte et générez
                votre signature.
              </p>
              <p>
                <TextField
                  required
                  value={name}
                  label={"Prénom, Nom"}
                  onChange={handleNameChange}
                  style={styles.inputs}
                />
                <TextField
                  required
                  type="number"
                  value={nameSizeD}
                  label={"Taille"}
                  onChange={handleNameSizeDChange}
                  style={styles.inputsPosSize}
                />
              </p>
              <p>
                <TextField
                  required
                  value={position}
                  label={"Position"}
                  onChange={handlePositionChange}
                  style={styles.inputs}
                />
                <TextField
                  required
                  type="number"
                  value={positionSizeD}
                  label={"Taille"}
                  onChange={handlePositionSizeDChange}
                  style={styles.inputsPosSize}
                />
              </p>
              <p>
                <TextField
                  required
                  value={phone}
                  label={"Numéro Téléphone"}
                  onChange={handlePhoneChange}
                  style={styles.inputs}
                />
                <TextField
                  required
                  type="number"
                  value={phoneSize}
                  label={"Taille"}
                  onChange={handlePhoneSizeChange}
                  style={styles.inputsPosSize}
                />
              </p>
              <p>
                <TextField
                  required
                  value={email}
                  label={"Email"}
                  onChange={handleEmailChange}
                  style={styles.inputs}
                />
                <TextField
                  required
                  type="number"
                  value={emailSize}
                  label={"Taille"}
                  onChange={handleEmailSizeChange}
                  style={styles.inputsPosSize}
                />
              </p>
              <p>
                <TextField
                  required
                  value={address}
                  label={"Adresse"}
                  onChange={handleAddressChange}
                  style={styles.inputsAddress}
                />
                {/* <FormControlLabel
                  control={
                    
                  }
                  style={styles.inputsAddressState}
                /> */}

                {/* <Switch
                  checked={state.checkedB}
                  onChange={handleChange}
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                /> */}
                <div style={styles.inputsAddressState}>
                  <Switch
                    checked={addressState}
                    onChange={handleAddressStateChange}
                    size="small"
                    color="primary"
                    inputProps={{
                      "aria-label": "On/Off Address",
                      title: "On/Off",
                    }}
                  />
                </div>
                <TextField
                  required
                  type="number"
                  value={addressSize}
                  label={"Taille"}
                  onChange={handleAddressSizeChange}
                  style={styles.inputsPosSize}
                />
              </p>

              <p align="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleHtmlGeneration}
                >
                  Génerer html
                </Button>
              </p>
            </Paper>
          </Grid>
          <Grid item md={8} sm={8} xs={12}>
            <Paper style={styles.paper}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                dangerouslySetInnerHTML={{ __html: html }}
              ></div>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <div style={{ width: "100%", maxWidth: "100%", margin: "100px auto" }}>
        <div
          style={{
            width: "100%",
            background: "#333",
            borderRadius: "4px",
          }}
          align="center"
        >
          <div
            className="html-block-header"
            style={{ padding: "1px 0 10px 0" }}
          >
            <h3>HTML OUTPUT</h3>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCopyClick}
            >
              COPIER!
            </Button>
          </div>
          <div
            align="left"
            style={{
              minHeight: "300px",
              overflow: "auto",
              background: "#424242",
            }}
          >
            <pre style={{ maxWidth: "500px" }}>
              <code ref={output}>{htmlCode}</code>
            </pre>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailSignature;

const styles = {
  paper: {
    padding: "20px",
    height: "100%",
  },
  inputs: {
    width: "80%",
  },
  inputsAddress: {
    width: "60%",
  },
  inputsPosSize: {
    width: "20%",
  },
  inputsAddressState: {
    display: "inline-block",
    width: "20%",
    textAlign: "center",
    verticalAlign: "bottom",
  },
};
