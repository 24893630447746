const API = async (method, url, data) => {
  var authorization = "";
  const user = JSON.parse(localStorage.getItem("user"));

  if (user) {
    authorization = user.token;
  }

  try {
    const fetchapi = await fetch(url, {
      method: method,
      headers: {
        Authorization: "bearer " + authorization,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const fetched = await fetchapi.json();
    return fetched;
  } catch (error) {
    console.log(error);
    return false;
  }
};
export default API;
