import { Switch, Redirect } from "react-router-dom";

import RouteWithLayout from "./RouteWithLayout";
import DefaultLayout from "../layouts/DefaultLayout";
import NotFound from "../layouts/404";

import { Route } from "react-router-dom";

import Dashboard from "../views/Dashboard";
import Login from "../views/Login";
import Settings from "../views/Settings";
import Profile from "../views/Profile";
import EditUser from "../views/Settings/EditUser";
import EmailSignature from "../views/EmailSignature";
import AddUser from "../views/Settings/AddUser";

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        component={Dashboard}
        exact
        layout={DefaultLayout}
        path="/"
      />
      <RouteWithLayout
        component={Profile}
        layout={DefaultLayout}
        exact
        path="/profile"
      />
      <RouteWithLayout
        component={Settings}
        layout={DefaultLayout}
        exact
        path="/settings"
      />
      <RouteWithLayout
        component={AddUser}
        layout={DefaultLayout}
        exact
        path="/users/new"
      />

      <RouteWithLayout
        component={EditUser}
        layout={DefaultLayout}
        exact
        path="/users/:id/update"
      />

      <RouteWithLayout
        component={EmailSignature}
        layout={DefaultLayout}
        exact
        path="/email-signature"
      />

      <Route component={NotFound} exact path="/not-found" />
      <Route component={Login} exact path="/login" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
