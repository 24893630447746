export const loggIn = (user) => {
  return {
    type: "SIGN_IN",
    payload: user,
  };
};

export const loadUser = (user) => {
  // console.log(user);
  //const data = loadUser();
  return {
    type: "IS_SIGNED_IN",
    payload: user,
  };
};

export const loggOut = () => {
  return {
    type: "SIGN_OUT",
  };
};

// const loadUser = async (token) => {
//   const dataJson = await fetch(
//     `${process.env.REACT_APP_API_BASE_URL}user/auth`,
//     {
//       method: "GET",
//       headers: {
//         Token: token,
//       },
//     }
//   );
//   const data = await dataJson.json();
//   return data;
// };
