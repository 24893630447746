import { useState } from "react";
import Loading from "../../../components/Loading";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { useHistory } from "react-router";
import API from "../../../utils/API";

const AddUser = () => {
  const history = useHistory();
  const [loadingOpen, setLoadingOpen] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const name = e.target.name.value;
    const password = e.target.password.value;
    const passwordVerif = e.target.passwordVerif.value;
    const role = e.target.role.value;

    if (password !== passwordVerif) {
      alert("les mots de passe ne sont pas identiues");
      return false;
    }

    try {
      setLoadingOpen(true);
      const res = await API(
        "POST",
        `${process.env.REACT_APP_API_BASE_URL}/users`,
        {
          name,
          password,
          email,
          role,
        }
      );

      if (res.code === 200) {
        setLoadingOpen(false);
        alert("Utilisateur ajouté avec succées");
        history.push("/settings");
      } else {
        alert("Une erreur est survenue, réessayer plus tard");
        setLoadingOpen(false);
      }
      // console.log(res);
    } catch (error) {
      setLoadingOpen(false);
      console.log(error);
    }
  };
  return (
    <>
      <Loading open={loadingOpen} />
      <Grid container justify="center" spacing={2}>
        <Grid item xs={12} sm={8} md={6}>
          <h1>Ajouter un utilisateur</h1>
          <Paper style={{ padding: "20px" }}>
            <form onSubmit={handleFormSubmit}>
              <div className="custom__form__control">
                <TextField
                  fullWidth
                  id="form_email"
                  name="email"
                  label="Email"
                />
              </div>
              <div className="custom__form__control">
                <TextField fullWidth id="form_name" name="name" label="Nom" />
              </div>
              <div className="custom__form__control">
                <TextField
                  fullWidth
                  id="form_password"
                  name="password"
                  label="Mot de passe"
                  type="password"
                />
              </div>{" "}
              <div className="custom__form__control">
                <TextField
                  fullWidth
                  id="form_passwordVerif"
                  name="passwordVerif"
                  label="confirmez le mot de passe"
                  type="password"
                />
              </div>
              <div className="custom__form__control">
                <InputLabel id="select-label">Role</InputLabel>
                <Select
                  fullWidth
                  labelId="select-label"
                  id="select-role"
                  name="role"
                  defaultValue={"ROLE_LIFE_MEMBER"}
                >
                  <MenuItem value={"ROLE_ADMIN"}>Administrateur</MenuItem>
                  <MenuItem value={"ROLE_COMPTA"}>Comptabilité</MenuItem>
                  <MenuItem value={"ROLE_RD"}>Relation donateur</MenuItem>
                  <MenuItem value={"ROLE_LIFE_MEMBER"}>
                    LIFE team member
                  </MenuItem>
                </Select>
              </div>
              <div align="center" className="custom__form__control">
                <Button variant="contained" color="primary" type="submit">
                  Ajouter
                </Button>
              </div>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
export default AddUser;
